<template>
    <div>
        <!--AxGdCommentList.vue
        {
            pageFlag: true,
            id: "ax_gd_comment",
            title: "",
            name: "ax_gd_comment",
            icon: "el-icon-document",
            component: "views/zb/AxGdCommentList.vue",
            componentdetail: "views/zb/AxGdCommentDetail.vue",
            visible: true
        }
        -->
        <el-container>
            <el-main>
                <el-button-group>
                    <el-button type="" size="mini" @click="syslistquery">查询</el-button>
                </el-button-group>
                <vxe-form size="mini">
                    <vxe-form-item title="单据编号" field="name" :item-render="{}" style="width:60px;">
                        <vxe-input v-model="p.name" size="mini" placeholder="名称"></vxe-input>
                    </vxe-form-item>
                </vxe-form>
                <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow highlight-hover-row size="mini" :data="rs" :height="meta.h">
                    <vxe-table-column field="code" title="采购单号" width="180"></vxe-table-column>
                    <vxe-table-column field="invcode" title="物料编号" width="150"></vxe-table-column>
                    <vxe-table-column field="othercode" title="单据信息" width="300"></vxe-table-column>
                    <!--<vxe-table-column field="datein" title="单据产生日期" width="150"></vxe-table-column>-->
                    <vxe-table-column field="dateintime" title="单据产生日期" width="200"></vxe-table-column>
                    <vxe-table-column field="id" title="操作" width="60">
                        <template #default="{row, rowIndex }">
                            <vxe-button size="mini" content="确定" @click="queding(row)"></vxe-button>
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <vxe-pager :align="'left'" background :current-page.sync="p1.page.current" :page-size.sync="p1.page.size" :total="p1.page.total" @page-change="pagechange" :page-sizes=[100,500,1000,10000] :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                </vxe-pager>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    export default {
        name: "ax_gd_commentlist",
        extends: ax.control1(),
        data() {
            return {
                p: { name: "" },
                model: "zb",
                settings: "axGdComment",
                vouchertype: "list",
            }
        },
        methods:{
            rowStyle ({ row, column, rowIndex, columnIndex }) {
                // 状态列字体颜色
                // alert("1");

                let styleJson = {
                }     
                if (row.statuss === '0' ) {
                   styleJson.color='red';
                } else if (row.statuss === '1') {
                   styleJson.color='#2E8B57';
                } 
                return styleJson;
            },
            queding(row){
                let _this = this;
                console.log(row);
                ax.base.post("/zb/axGdComment/updatestatus", row).then(data => {
                _this.syslistquery();
                }).catch(data => {
                    _this.$XModal.message({ message: data.data, status: 'error' })
                });
            },
        },
    }
</script>